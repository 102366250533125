<template>
  <div class="popup_wrap"  style="width:850px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form id="mailFrm">
      <div class="popup_cont">
        <h1 class="page_title">{{ $t('cp.CTRL020P110.001') }}</h1>
        <div class="content_box">
          <table class="tbl_row">
            <colgroup>
              <col width="120">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('cp.CTRL020P110.002') }}</th>
                <td>
                  <input type="text" id="toEmail" name="" v-model="params.toEmail">
                </td>
              </tr>
              <tr>
                <th>{{ $t('cp.CTRL020P110.003') }}</th>
                <td>
                  <input type="text" id="fromEmail" name="" v-model="params.fromEmail" readonly>
                </td>
              </tr>
              <tr>
                <th>{{ $t('cp.CTRL020P110.004') }}</th>
                <td>
                  <input type="text" id="subject" name="" maxlength="500" v-model="params.subject">
                </td>
              </tr>
              <tr>
                <th v-html="$t('cp.CTRL020P110.005').replace('\n', '<br>')"></th>
                <td>
                  <textarea style="height:400px;" id="message" maxlength="4000" v-model="params.message"></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt10 text_center">
          <a class="button blue lg" href="#" @click.prevent="sendEmail">{{ $t('cp.CTRL020P110.006') }}</a>
          <a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('cp.COMMON.008') }}</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/api/services/auth'
import commons from '@/api/services/commons'

export default {
  name: 'EmailPop',
  components: {},
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          recptEmlAddr: ''
        }
      }
    }
  },
  data () {
    return {
      params: {
        toEmail: '',
        fromEmail: '',
        subject: '',
        message: ''
      }
    }
  },
  watch: {},
  created () {
    this.initEmailForm()
  },
  methods: {
    initEmailForm () {
      const jwt = this.$store.state.auth.loginInfo.jwt
      auth.info(jwt).then((response) => {
        this.params.toEmail = this.parentInfo.toEmail
        this.params.fromEmail = response.data.userEmail
      }).catch(err => {
        console.log(err)
      })
    },
    async sendEmail () {
      const toEmailList = this.params.toEmail.split(';').filter(email => this.$ekmtcCommon.checkEmail(email))
      const fromEmail = this.params.fromEmail
      const subject = this.params.subject
      const message = this.params.message
      let isValid = true

      const validCheckList = {
        toEmail: Array.isArray(toEmailList) && toEmailList.length >= 0,
        fromEmail: this.$ekmtcCommon.checkEmail(fromEmail),
        subject: !this.$ekmtcCommon.isEmpty(subject),
        message: !this.$ekmtcCommon.isEmpty(message)
      }

      const alertMapper = {
        toEmail: this.$t('cp.CTRL020P110.008'),
        fromEmail: this.$t('cp.CTRL020P110.008'),
        subject: this.$t('cp.CTRL020P110.010'),
        message: this.$t('cp.CTRL020P110.011')
      }

      for (const elem of Object.keys(validCheckList)) {
        if (!validCheckList[elem]) {
          this.$ekmtcCommon.alertDefault(alertMapper[elem])
          isValid = false
          return
        }
      }

      if (isValid) {
        const promiseList = []

        for (const toEmail of toEmailList) {
          const formData = new FormData()
          formData.append('toEmail', toEmail)
          formData.append('fromEmail', fromEmail)
          formData.append('subject', subject)
          formData.append('message', message)

          const promise = await commons.sendEmail(formData).then(response => {
            const value = {
              status: response?.status,
              data: {
                toEmail: toEmail
              }
            }
            return value
          })
          promiseList.push(promise)
        }

        await Promise.allSettled(promiseList).then(response => {
          const statusList = response.map(elem => elem.value.status)

          if (statusList.every(elem => elem === 200)) {
            this.$ekmtcCommon.alertDefault(this.$t('cp.CTRL020P110.012'))
          } else if (statusList.every(elem => elem !== 200)) {
            this.$ekmtcCommon.alertDefault(this.$t('cp.CTRL020P110.013'))
          } else {
            const responseFailed = response.filter(elem => elem.value.status !== 200)
            const toEmailFailedList = responseFailed.map(elem => elem.value.data.toEmail)
            const toEmailListContainer = document.createElement('ul')
            toEmailListContainer.setAttribute('class', 'cp-to-email-list cp-scroll cp-scroll--auto mt10')

            for (const toEmailFailed of toEmailFailedList) {
              const li = document.createElement('li')
              const txt = document.createTextNode(toEmailFailed)
              li.appendChild(txt)
              toEmailListContainer.appendChild(li)
            }

            const msg = `${this.$t('cp.CTRL020P110.014')}<br>${toEmailListContainer.outerHTML}`
            this.$ekmtcCommon.alertDefault(msg)
          }
        }).catch(e => {
          console.error('promise-all', e)
        })
      }
      this.$emit('close')
      this.layerClose()
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    }
  }
}
</script>
<style>
  .cp-to-email-list {
    height: 120px;
  }
</style>
