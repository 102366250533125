var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "850px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("form", { attrs: { id: "mailFrm" } }, [
        _c("div", { staticClass: "popup_cont" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("cp.CTRL020P110.001"))),
          ]),
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P110.002")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.toEmail,
                          expression: "params.toEmail",
                        },
                      ],
                      attrs: { type: "text", id: "toEmail", name: "" },
                      domProps: { value: _vm.params.toEmail },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "toEmail", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P110.003")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.fromEmail,
                          expression: "params.fromEmail",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "fromEmail",
                        name: "",
                        readonly: "",
                      },
                      domProps: { value: _vm.params.fromEmail },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "fromEmail", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P110.004")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.subject,
                          expression: "params.subject",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "subject",
                        name: "",
                        maxlength: "500",
                      },
                      domProps: { value: _vm.params.subject },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "subject", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("cp.CTRL020P110.005").replace("\n", "<br>")
                      ),
                    },
                  }),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.message,
                          expression: "params.message",
                        },
                      ],
                      staticStyle: { height: "400px" },
                      attrs: { id: "message", maxlength: "4000" },
                      domProps: { value: _vm.params.message },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "message", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.sendEmail.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("cp.CTRL020P110.006")))]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("cp.COMMON.008")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "120" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }